import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useRef, useState } from 'react';
import { useMeasure } from 'react-use';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { searchInputSelector } from '@/models/search/selectors';
import { apiSelector } from '@/models/api/selectors';
import { searchTrie } from '@/models/search/search';
import { ASSISTANCE_CODE } from '@/libs/static';
import { setSearchInput } from '@/models/search/setSearchInput';
import { ACTIVE_REST_OPTIONS_SEARCH_CODE, SPORTS_SEARCH_CODE, } from '@/models/search/filtersSearchTags';
import { analytics } from '@/libs/analytics';
import { useStyles } from './styles';
let hasSetAssistancesToSearch = false;
let hasSetSportsToSearch = false;
let hasSentEvent = false;
export const FiltersSearch = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const value = useSelector(searchInputSelector);
    const { activeRestServices, commonSports, competitionSports, assistances } = useSelector(apiSelector);
    useEffect(() => {
        if (!hasSetAssistancesToSearch) {
            searchTrie.update(ASSISTANCE_CODE, assistances.map(({ name }) => name));
            hasSetAssistancesToSearch = true;
        }
    }, [assistances]);
    useEffect(() => {
        if (!hasSetSportsToSearch) {
            const updateOptions = { minWordLengthToInsert: 3 };
            searchTrie.update(ACTIVE_REST_OPTIONS_SEARCH_CODE, activeRestServices.map(({ name }) => name), updateOptions);
            searchTrie.update(SPORTS_SEARCH_CODE, [...commonSports, ...competitionSports].map(({ name }) => name), updateOptions);
            hasSetSportsToSearch = true;
        }
    }, [activeRestServices, commonSports, competitionSports]);
    const [isFocused, setIsFocused] = useState(false);
    const [withTransition, setWithTransition] = useState(false);
    const inputRef = useRef(null);
    const [ref, { width: measuredWidth }] = useMeasure();
    const width = measuredWidth || 320;
    useEffect(() => {
        const timeout = setTimeout(() => {
            setWithTransition(true);
        }, 400);
        return () => {
            clearTimeout(timeout);
        };
    }, []);
    useEffect(() => {
        const handleFocus = () => setIsFocused(true);
        const handleBlur = () => setIsFocused(false);
        const inputElement = inputRef.current;
        if (inputElement) {
            inputElement.addEventListener('focus', handleFocus);
            inputElement.addEventListener('blur', handleBlur);
        }
        return () => {
            if (inputElement) {
                inputElement.removeEventListener('focus', handleFocus);
                inputElement.removeEventListener('blur', handleBlur);
            }
        };
    }, [inputRef]);
    const setInput = (value) => {
        dispatch(setSearchInput(value));
    };
    const active = Boolean(isFocused || value);
    const animationVariant = active ? 'active' : 'inactive';
    return (_jsx("div", { className: classes.searchWrapper, children: _jsxs("div", { className: classes.searchInputContainer, ref: ref, children: [_jsx(motion.svg, { height: "18", width: "18", viewBox: "0 0 16 16", "aria-hidden": "true", variants: {
                        active: { left: 13 },
                        inactive: { left: width / 2 - 70 },
                    }, initial: animationVariant, animate: animationVariant, "data-with-transition": withTransition, children: _jsx("path", { d: "M11.87 10.835c.018.015.035.03.051.047l3.864 3.863a.735.735 0 1 1-1.04 1.04l-3.863-3.864a.744.744 0 0 1-.047-.051 6.667 6.667 0 1 1 1.035-1.035zM6.667 12a5.333 5.333 0 1 0 0-10.667 5.333 5.333 0 0 0 0 10.667z" }) }), _jsx(motion.input, { placeholder: "\u041F\u043E\u0438\u0441\u043A \u043F\u043E \u043E\u043F\u0446\u0438\u044F\u043C", value: value, onChange: (e) => {
                        setInput(e.target.value);
                        if (!hasSentEvent) {
                            hasSentEvent = true;
                            analytics.sendEvent({
                                action: 'travel_filters_search_usage',
                                label: 'Действие пользователя',
                                category: 'Cherehapa',
                            });
                        }
                    }, className: classes.search, "data-empty": !value, "data-active": active, ref: inputRef, "data-with-transition": withTransition, variants: {
                        active: { paddingLeft: 39 },
                        inactive: { paddingLeft: width / 2 - 46 },
                    }, initial: animationVariant, animate: animationVariant, spellCheck: false, maxLength: 40 }), _jsx(motion.button, { className: classes.searchEraseButton, onClick: () => {
                        setInput('');
                    }, variants: {
                        hidden: { opacity: 0 },
                        visible: { opacity: 1 },
                    }, tabIndex: value ? 0 : -1, initial: "hidden", animate: value ? 'visible' : 'hidden', style: { pointerEvents: value ? 'auto' : 'none' }, transition: { duration: 0.2 }, children: _jsx("svg", { fill: "none", viewBox: "0 0 17 17", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M6.61856 8.39393L6.72463 8.5L6.61856 8.60607L1.51899 13.7056C1.03116 14.1935 1.03057 14.989 1.51923 15.4812C2.01123 15.973 2.80245 15.9729 3.29436 15.481L8.39393 10.3814L8.5 10.2754L8.60607 10.3814L13.7056 15.481C14.1976 15.973 14.989 15.973 15.481 15.481C15.973 14.989 15.973 14.1976 15.481 13.7056L10.3814 8.60607L10.2754 8.5L10.3814 8.39393L15.481 3.29436C15.973 2.80238 15.973 2.01098 15.481 1.51899C14.989 1.027 14.1976 1.027 13.7056 1.51899L8.60607 6.61856L8.5 6.72463L8.39393 6.61856L3.29436 1.51899C2.80237 1.027 2.01098 1.027 1.51899 1.51899C1.027 2.01098 1.027 2.80238 1.51899 3.29436L6.61856 8.39393Z", strokeWidth: "0.2" }) }) })] }) }));
});
