import { COMPARING_STATE_NAMESPACE } from '@/models/comparing';
import { BASKET_STATE_NAMESPACE } from '@/models/basket';
import { USER_STATE_NAMESPACE } from '@/models/user';
import { Environments } from './types/global';
export const DEFAULT_TOURIST_AGE = 30;
export const SAVING_IN_BROWSER_INTERVAL_MS = 1000;
export const DEBOUNCE_CALCULATION_INTERVAL_MS = 1000;
export const REQUEST_STATE_NAMESPACE = 'request';
export const FORMS_STATE_NAMESPACE = 'form';
export const LOCALSTORAGE_MODELS = [
    REQUEST_STATE_NAMESPACE,
    BASKET_STATE_NAMESPACE,
    FORMS_STATE_NAMESPACE,
    COMPARING_STATE_NAMESPACE,
    USER_STATE_NAMESPACE,
    FORMS_STATE_NAMESPACE,
];
export const SEC = 1000;
export const MIN = 60 * SEC;
export const EXPIRES_CALCULATION_TIME = 20 * MIN;
export const IS_PRODUCTION = __ENV__ === Environments.PRODUCTION;
export const IS_DEVELOPMENT = __ENV__ === Environments.DEVELOPMENT;
export const SENTRY_CONFIG = {
    dsn: __SENTRY_DSN__,
    environment: __ENV__,
    ...(IS_DEVELOPMENT && { debug: true }),
};
export const MARKETING_PARAMS = [
    'partnerId',
    'marker',
    'marker2',
    'srcsale',
];
export const MODAL_PRODUCT_ID_QUERY_PARAM = 'modalProductId';
export const IS_IGNORE_LOCAL_STORAGE = 'isIgnoreLocalStorage';
export const ORDER_ID_QUERY_PARAM = 'orderId';
export const UPSELL_ENABLED = Boolean(Number(__UPSELL_ENABLED__));
export const RESTORABLE_QS_PARAMS = {
    mobileFilters: 'mobileFilters',
    [MODAL_PRODUCT_ID_QUERY_PARAM]: MODAL_PRODUCT_ID_QUERY_PARAM,
    isIgnoreLocalStorage: 'isIgnoreLocalStorage',
};
export const ASSETS_SRC = {
    FONTS: `${__STATIC_URL__}front/fonts`,
    IMAGES: `${__STATIC_URL__}front/images`,
    VIDEO: `${__STATIC_URL__}front/video`,
};
export const SCRIPTS = {
    FONTS: `${ASSETS_SRC.FONTS}/fonts.css`,
};
export const iframeResizerScript = __WIDGETS_STATIC_URL__ + 'widgets/iframeResizer.contentWindow.min.js';
export const DATE_MASK = { mask: '00.00.0000' };
export const FORM_DATE_FORMAT = 'dd.MM.yyyy';
export const DENIED_MESSAGES_FOR_BREADCRUMBS = [
    'Fast Refresh',
    'Reach goal',
    'PageView. Counter',
    'Leadhit',
];
export const DENIED_URLS_FOR_BREADCRUMBS = [
    'mc.yandex.ru',
    'google.analytics.com',
    'gso.amocrm.ru',
    'leadhit.io',
    'stats.g.doubleclick.net',
];
export const MIN_TOURISTS_AMOUNT = 1;
export const MAX_TOURISTS_AMOUNT = 10;
export const MIN_EMAILS_AMOUNT = 1;
export const MAX_EMAILS_AMOUNT = 4;
export const MAX_AGE = 100;
export const MIN_INSURER_AGE = 18;
export const MAX_NAME_LENGTH = 50;
export const CURRENCIES_BADGES = {
    rub: '₽',
    usd: '$',
    eur: '€',
};
export const MAXIMUM_VISIBLE_SERVICE = 10;
export const ADDITIONAL_SHARE_TEXT = 'Смотри какую страховку нашёл на Черехапа: ';
export const COUNTRY_CODES_WITH_INITIAL_CURRENCY_EURO = [
    'schengen',
    'austria',
    'belgium',
    'czech_republic',
    'denmark',
    'estonia',
    'finland',
    'france',
    'germany',
    'greece',
    'hungary',
    'iceland',
    'italy',
    'latvia',
    'liechtenstein',
    'lithuania',
    'luxembourg',
    'malta',
    'netherlands',
    'norway',
    'poland',
    'portugal',
    'slovakia',
    'slovenia',
    'spain',
    'sweden',
    'switzerland',
    'albania',
    'andorra',
    'antarctic_territories',
    'bosnia_and_herzegovina',
    'bulgaria',
    'croatia',
    'cyprus',
    'faroe_islands',
    'gibraltar',
    'guernsey',
    'ireland',
    'isle_of_man',
    'jersey',
    'macedonia',
    'monaco',
    'montenegro',
    'romania',
    'san_marino',
    'serbia',
    'svalbard',
    'united_kingdom',
    'vatican',
];
export const ACCOUNT_URL = __HOMEPAGE__ + '/account';
export const ACCOUNT_POLICIES_URL = __HOMEPAGE__ + '/account/policies/travel';
export const VASCO_PARTNER_ID = 9004;
export const VASCO_PROHIBITED_MEDICINE_SERVICES = [30000, 35000];
export const VASCO_HEADER_PROPS = {
    status: undefined,
    withContacts: false,
    withMenu: false,
    homepageUrl: 'https://vasco.cherehapa.ru/vs',
    leading: undefined,
};
