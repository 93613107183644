import { createUseStyles } from 'react-jss';
import { HEADER_HEIGHT } from '@/components/common/Header/styles';
import { withAlpha } from '@/styles/utils';
import { DEKSTOP_FONT_SIZES, DESKTOP_FILTERS_BREAKPOINT, DESKTOP_SIZES_BREAKPOINT, MOBILE_FONT_SIZES, GLOBAL_LETTER_SPACING, } from '@/styles/themes/components';
import { BREAKPOINTS } from '@/styles/breakpoints';
import { mixins } from '@/styles/mixins';
export const FILTERS_PANEL_BOTTOM = 40;
export const useStyles = createUseStyles((theme) => ({
    '@keyframes loading': {
        from: {
            width: '0%',
        },
        to: {
            width: '100%',
        },
    },
    wrapper: {
        paddingTop: `${HEADER_HEIGHT}px`,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            paddingTop: '0px',
            width: '320px',
        },
    },
    header: {
        height: '52px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'fixed',
        top: '0px',
        left: '0px',
        right: '0px',
        background: theme.colors.cloud,
        zIndex: 1,
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            display: 'none',
        },
    },
    headerTitle: {
        fontSize: MOBILE_FONT_SIZES.title,
        color: theme.colors.sky,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.title,
        },
    },
    filtersSkeletonDesktop: {
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        width: '320px',
        height: '600px',
        borderRadius: '10px',
        background: theme.colors.cloud,
        flexDirection: 'column',
        '& > span': {
            fontSize: DEKSTOP_FONT_SIZES.main,
            color: theme.colors.brand,
        },
        boxShadow: theme.elevations.cloud,
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            display: 'flex',
        },
    },
    headerSkeletonMobile: {
        padding: '15px',
    },
    filtersSkeletonMobile: {
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
        background: theme.colors.cloud,
        overflow: 'hidden',
        gap: '20px',
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            display: 'none',
        },
    },
    filtersSkeletonMobileRow: {
        overflow: 'hidden',
        borderRadius: '7px',
    },
    loader: {
        width: '190px',
        height: '2px',
        marginBottom: '10px',
        background: theme.colors.brandTertiary,
        '& > div': {
            height: '2px',
            background: theme.colors.brand,
            animation: '$loading 1.5s 0s infinite',
        },
    },
    blockWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        background: theme.colors.cloud,
        borderRadius: '12px',
        '& > *': {
            padding: '6px 0',
            '&:first-of-type': {
                borderBottom: `1px solid ${withAlpha(theme.colors.textPrimary, 0.2)}`,
                padding: 12,
            },
        },
        boxShadow: theme.elevations.cloud,
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            maxWidth: 320,
        },
    },
    firstBlockWrapper: {
        borderRadius: '0px 0px 12px 12px',
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            borderRadius: '12px',
        },
    },
    lastBlockWrapper: {
        marginBottom: '0px',
        '&:after': {
            content: '""',
            display: 'block',
            height: '55px',
            background: theme.colors.cloud,
        },
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            marginBottom: '8px',
            '&:after': {
                content: '""',
                display: 'none',
            },
        },
    },
    medicineHeaderTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    medicineBlockWrapper: {},
    medicineBlockHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    medicineBlockHintList: {
        padding: '4px 6px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    medicineBlockHintListItem: {
        textAlign: 'left',
        display: 'flex',
        letterSpacing: GLOBAL_LETTER_SPACING,
        fontSize: MOBILE_FONT_SIZES.main,
        color: theme.colors.sky,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    medicineHintCheckIcon: {
        marginRight: 11,
        marginTop: '3px',
        flexShrink: 0,
        width: 12,
        height: 12,
        '& path': {
            fill: theme.colors.success,
        },
    },
    blockTitle: {
        fontWeight: 500,
        fontSize: MOBILE_FONT_SIZES.main,
        color: theme.colors.sky,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    subDescription: {
        color: theme.colors.textPrimary,
        fontSize: MOBILE_FONT_SIZES.small,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.small,
        },
    },
    serviceName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    accordionTitleText: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        fontSize: MOBILE_FONT_SIZES.main,
        color: theme.colors.sky,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    accordionServicesCount: {
        marginLeft: '5px',
        color: theme.colors.brand,
        boxShadow: theme.elevations.cloud,
        backgroundColor: theme.colors.cloud,
        borderRadius: 12,
    },
    sportsWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 7,
        padding: '5px 12px',
    },
    searchWrapper: {
        borderRadius: '0 0 12px 12px',
        width: '100%',
        backgroundColor: theme.colors.cloud,
        boxShadow: theme.elevations.cloud,
        padding: 12,
        '& svg': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            pointerEvents: 'none',
            marginTop: 1,
            '& path': {
                fill: theme.colors.textSecondary,
            },
        },
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            borderRadius: 12,
            padding: 0,
        },
    },
    searchInputContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    search: {
        height: 50,
        borderRadius: 12,
        width: '100%',
        border: `1px solid ${theme.colors.textTertiary}`,
        backgroundColor: theme.colors.cloud,
        outline: '0px solid transparent',
        color: theme.colors.sky,
        '-webkit-tap-highlight-color': 'transparent',
        transition: 'outline 0.2s',
        '&[data-active="true"]': {
            borderColor: 'transparent',
            outline: `2px solid ${theme.colors.brand}`,
        },
        ...mixins.inputPlaceholderColor(theme.colors.textSecondary),
        fontSize: MOBILE_FONT_SIZES.input,
        letterSpacing: GLOBAL_LETTER_SPACING,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.input,
            border: 'none',
        },
        [theme.breakpoints.up(BREAKPOINTS.md)]: {
            height: 42,
        },
    },
    searchEraseButton: {
        position: 'absolute',
        borderRadius: 999,
        width: 46,
        height: 46,
        right: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '50%',
        transform: 'translateY(-50%)',
        outlineOffset: -8,
        '& svg': {
            width: 20,
            height: 20,
            '& path': {
                fill: theme.colors.brand,
            },
        },
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            width: 40,
            height: 40,
            right: 2,
            '& svg': {
                width: 17,
                height: 17,
            },
        },
    },
    searchNotFound: {
        fontSize: MOBILE_FONT_SIZES.small,
        letterSpacing: GLOBAL_LETTER_SPACING,
        color: theme.colors.sky,
        textAlign: 'center',
        marginTop: 8,
    },
    searchSecretResultContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    searchAnimationContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        isolation: 'isolate',
        height: 140,
    },
    searchSportsInfo: {
        display: 'flex',
        gap: 8,
        '& p': {
            fontSize: MOBILE_FONT_SIZES.small,
            letterSpacing: GLOBAL_LETTER_SPACING,
            color: theme.colors.sky,
        },
        '& svg': {
            flexShrink: 0,
            '& path': {
                fill: theme.colors.textSecondary,
            },
        },
    },
    medicineButtons: {
        display: 'flex',
        padding: '1px 6px',
        margin: '2px 0 8px',
        isolation: 'isolate',
    },
    medicineButton: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 1px',
        position: 'relative',
        transition: theme.transition.global,
        borderRadius: 10,
        outlineOffset: -1,
        WebkitTapHighlightColor: 'transparent',
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            padding: '7px 1px',
        },
    },
    medicineButtonBackground: {
        zIndex: 2,
        borderRadius: 10,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: -1,
        right: -1,
        backgroundColor: theme.colors.brand,
    },
    medicineButtonLabel: {
        color: theme.colors.brand,
        display: 'flex',
        gap: 1.5,
        zIndex: 3,
        transition: theme.transition.global,
        fontSize: MOBILE_FONT_SIZES.main,
        letterSpacing: '-0.7px',
        '&[data-selected="true"]': {
            transitionDelay: '0.2s',
            color: theme.colors.white,
        },
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    moped: {
        display: 'flex',
        alignItems: 'center',
    },
}), { name: 'filter-layout' });
