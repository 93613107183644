import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { motion } from 'framer-motion';
import { addDays, addYears, differenceInDays, formatDate, } from '@che-ins-ui/datepicker';
import { useMeasure } from 'react-use';
import { MULTIPOLICY_OPTIONS } from '@/models/request';
import { declOfNum, displayDate } from '@/libs/helpers';
import { getTypes } from '@/libs/static';
import { TravelFormInput } from '../TravelFormInput/TravelFormInput';
import { TravelFormDropdown } from '../TravelFormDropdown/TravelFormDropdown';
import { useStyles } from './styles';
import { Calendar } from './Calendar';
import { CalendarFooter } from './CalendarFooter';
import { CalendarHeader } from './CalendarHeader';
import { useDatepickerControls } from './useDatepickerControls';
import { onDatepickerInteraction, onMultipolicySelect } from './helpers';
const DATE_FORMAT = 'ww, dd mm';
export const Datepicker = memo(() => {
    const classes = useStyles();
    const { dateStart, dateEnd, isMultipolicySelected, multipolicyValue, containerRef, lastOpenedType, focused, setFocused, startInputRef, endInputRef, selectDate, onReset, enableMultipolicy, disableMultipolicy, } = useDatepickerControls();
    const startDate = dateStart ? new Date(formatDate(dateStart, true)) : null;
    const endDate = dateEnd ? new Date(formatDate(dateEnd, true)) : null;
    const insuredDaysLabel = useMemo(() => {
        if (!startDate || !endDate || isMultipolicySelected) {
            return null;
        }
        const insuredDaysCount = differenceInDays(startDate, endDate) + 1;
        return [
            'Выбрано',
            insuredDaysCount,
            declOfNum(insuredDaysCount, getTypes('DAY')),
        ].join(' ');
    }, [isMultipolicySelected, startDate, endDate]);
    const getEndValue = () => {
        if (isMultipolicySelected) {
            const selected = MULTIPOLICY_OPTIONS.find(({ value }) => value === multipolicyValue);
            if (!selected) {
                return 'Не выбрано';
            }
            return 'shortLabel' in selected
                ? selected.shortLabel
                : selected.selectOptionLabel;
        }
        return endDate ? displayDate(endDate, DATE_FORMAT) : '';
    };
    const getCalendarSelectedEndDate = () => {
        if (isMultipolicySelected) {
            return startDate
                ? formatDate(addDays(addYears(startDate, 1), -1))
                : null;
        }
        return dateEnd;
    };
    const getEndLabel = () => {
        if (isMultipolicySelected) {
            return 'Годовой полис';
        }
        return insuredDaysLabel || 'Обратно';
    };
    const [inputsRef, { width: inputsWidth }] = useMeasure();
    return (_jsx("div", { className: classes.datePicker, ref: inputsRef, children: _jsxs("div", { className: classes.relative, ref: containerRef, "data-type": lastOpenedType, children: [_jsxs(motion.div, { layout: true, className: classes.inputs, children: [_jsx(TravelFormInput, { value: startDate ? displayDate(startDate, DATE_FORMAT) : '', label: "\u041A\u043E\u0433\u0434\u0430", placeholder: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0434\u0430\u0442\u0443", isFocused: focused === 'start', onFocus: () => setFocused('start'), onClose: () => setFocused(null), type: "date-from", ref: startInputRef }), _jsx(TravelFormInput, { value: getEndValue(), label: getEndLabel(), placeholder: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0434\u0430\u0442\u0443", isFocused: focused === 'end', onFocus: () => setFocused('end'), onClose: () => setFocused(null), type: "date-to", ref: endInputRef })] }), _jsx(TravelFormDropdown, { isOpenend: Boolean(focused), children: _jsx(Calendar, { onSelectDate: (date) => {
                            selectDate(date);
                            onDatepickerInteraction();
                        }, selectedStartDate: dateStart, selectedEndDate: getCalendarSelectedEndDate(), type: focused || 'start', inputsWidth: inputsWidth, header: _jsx(CalendarHeader, { onReset: () => {
                                onReset();
                                onDatepickerInteraction();
                            }, selectedStartDate: dateStart, selectedEndDate: dateEnd, enableMultipolicy: (value) => {
                                enableMultipolicy(value);
                                onDatepickerInteraction();
                                onMultipolicySelect();
                            }, disableMultipolicy: () => {
                                disableMultipolicy();
                                onDatepickerInteraction();
                                onMultipolicySelect();
                            } }), footer: _jsx(CalendarFooter, {}) }) })] }) }));
});
