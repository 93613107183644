import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useMeasure } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames';
import { useStyles } from './styles';
export const TravelFormDropdown = memo(({ children, isOpenend, shouldStretch, className, }) => {
    const classes = useStyles();
    const [measureRef, { height }] = useMeasure();
    return (_jsx(AnimatePresence, { children: isOpenend && (_jsx(motion.div, { initial: { opacity: 0.8, y: -25, scaleY: 0.9, height }, animate: {
                opacity: 1,
                y: 0,
                scale: 1,
                scaleY: 1,
                height,
            }, exit: {
                opacity: 0,
                y: -10,
                transition: { duration: 0.1 },
            }, transition: { duration: 0.15 }, className: classNames(classes.root, className), "data-stretch": shouldStretch, children: _jsx("div", { ref: measureRef, children: children }) })) }));
});
