import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleElementInArray } from '@che-ins-ui/utils';
import { requestSelector } from '@/models/request/selectors';
import { MOUNTAIN_CLIMBING_SPORT_CODE, MOUNTAIN_TRACKING_SPORT_CODE, RESCUE_ACTIVITIES_CODE, SEARCH_ACTIVITIES_CODE, TRACKING_SPORT_CODE, } from '@/libs/static';
import { requestActions } from '@/models/request';
import { analytics } from '@/libs/analytics';
import { useLocalStorage } from '@/libs/hooks/useLocalStorage';
import { useStyles } from './styles';
import { MustReadInfoBlock, MustReadInfoOptionEnabler, } from './MustReadInfoBlock';
let hasSentEvent = false;
const onEnable = () => {
    if (!hasSentEvent) {
        hasSentEvent = true;
        analytics.sendEvent({
            action: 'travel_nepal_recommendations_selection',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    }
};
export const MustReadNepal = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { sports, services } = useSelector(requestSelector);
    const [isOpened, setIsOpened] = useLocalStorage('must-read-nepal', false);
    const tabIndex = isOpened ? 0 : -1;
    const isTrackingEnabled = sports.includes(TRACKING_SPORT_CODE);
    const isMTEnabled = sports.includes(MOUNTAIN_TRACKING_SPORT_CODE);
    const isMCEnabled = sports.includes(MOUNTAIN_CLIMBING_SPORT_CODE);
    const isRAEnabled = Boolean(services?.[RESCUE_ACTIVITIES_CODE]);
    const isSAEnabled = Boolean(services?.[SEARCH_ACTIVITIES_CODE]);
    const allEnabled = isTrackingEnabled &&
        isMTEnabled &&
        isMCEnabled &&
        isRAEnabled &&
        isSAEnabled;
    const toggleSport = (sportCode) => {
        const newSports = toggleElementInArray(sports, sportCode);
        dispatch(requestActions.setSports(newSports));
    };
    const toggleService = (serviceCode) => {
        dispatch(requestActions.setServices({
            [serviceCode]: services[serviceCode] ? 0 : 1,
        }));
    };
    return (_jsx(MustReadInfoBlock, { isOpened: isOpened, setIsOpened: setIsOpened, icon: _jsx("svg", { width: 32, height: 32, viewBox: "0 -960 960 960", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "m40-240 240-320 180 240h300L560-586 460-454l-50-66 150-200 360 480H40Zm521-80Zm-361 0h160l-80-107-80 107Zm0 0h160-160Z" }) }), title: "\u0421\u043E\u0431\u0440\u0430\u043B\u0438\u0441\u044C \u0432 \u041D\u0435\u043F\u0430\u043B?", areAllItemsEnabled: allEnabled, children: _jsxs("div", { className: classes.infoContainer, children: [_jsxs("div", { children: [_jsx("p", { className: classes.info, children: "\u041A\u0430\u0442\u043C\u0430\u043D\u0434\u0443 - \u0441\u0442\u043E\u043B\u0438\u0446\u0430 \u041D\u0435\u043F\u0430\u043B\u0430, \u0440\u0430\u0441\u043F\u043E\u043B\u043E\u0436\u0435\u043D\u0430\u044F \u043D\u0430 \u0432\u044B\u0441\u043E\u0442\u0435 1350 \u043C\u0435\u0442\u0440\u043E\u0432 \u043D\u0430\u0434 \u0443\u0440\u043E\u0432\u043D\u0435\u043C \u043C\u043E\u0440\u044F, \u0441\u0447\u0438\u0442\u0430\u0435\u0442\u0441\u044F \u043D\u0430\u0441\u0442\u043E\u044F\u0449\u0438\u043C \u0433\u043E\u0440\u043D\u044B\u043C \u0433\u043E\u0440\u043E\u0434\u043E\u043C! \u041F\u043E\u044D\u0442\u043E\u043C\u0443 \u0435\u0441\u043B\u0438 \u0432\u044B \u043F\u043B\u0430\u043D\u0438\u0440\u0443\u0435\u0442\u0435 \u043E\u0441\u043C\u043E\u0442\u0440 \u0431\u0443\u0434\u0434\u0438\u0439\u0441\u043A\u0438\u0445 \u0441\u0432\u044F\u0442\u044B\u043D\u044C \u0432 \u043E\u043A\u0440\u0435\u0441\u043D\u043E\u0441\u0442\u044F\u0445 \u041A\u0430\u0442\u043C\u0430\u043D\u0434\u0443, \u0442\u043E \u0434\u043E\u0431\u0430\u0432\u044C\u0442\u0435 \u0432 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u043A\u0443:" }), [
                            {
                                label: 'Трекинг',
                                isActive: isTrackingEnabled,
                                onCheck: () => toggleSport(TRACKING_SPORT_CODE),
                            },
                            {
                                label: 'Эвакуацию вертолётом',
                                isActive: isSAEnabled,
                                onCheck: () => toggleService(SEARCH_ACTIVITIES_CODE),
                            },
                        ].map(({ label, isActive, onCheck }) => (_jsx(MustReadInfoOptionEnabler, { label: label, isActive: isActive, onCheck: () => {
                                onCheck();
                                onEnable();
                            }, tabIndex: tabIndex }, label)))] }), _jsxs("div", { children: [_jsx("p", { className: classes.info, children: "\u0415\u0441\u043B\u0438 \u0432 \u0432\u0430\u0448\u0438\u0445 \u043F\u043B\u0430\u043D\u0430\u0445 \u043F\u043E\u043A\u043E\u0440\u0435\u043D\u0438\u0435 \u0432\u0435\u0440\u0448\u0438\u043D, \u0442\u043E\u0433\u0434\u0430 \u043D\u0435 \u043E\u0431\u043E\u0439\u0442\u0438\u0441\u044C \u0431\u0435\u0437:" }), [
                            {
                                label: 'Горный трекинг',
                                isActive: isMTEnabled,
                                onCheck: () => toggleSport(MOUNTAIN_TRACKING_SPORT_CODE),
                            },
                            {
                                label: 'Альпинизм',
                                isActive: isMCEnabled,
                                onCheck: () => toggleSport(MOUNTAIN_CLIMBING_SPORT_CODE),
                            },
                            {
                                label: 'Поисково-спасательные работы',
                                isActive: isRAEnabled,
                                onCheck: () => toggleService(RESCUE_ACTIVITIES_CODE),
                            },
                        ].map(({ label, isActive, onCheck }) => (_jsx(MustReadInfoOptionEnabler, { label: label, isActive: isActive, onCheck: () => {
                                onCheck();
                                onEnable();
                            }, tabIndex: tabIndex }, label)))] })] }) }));
});
